import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import styled from "@emotion/styled"

import Info from "../../../components/Info"
import Instructor from "../../../components/InstructorItem"

import { mobile, tablet, desktop } from "../../../utils/breakpoint"

const List = styled.div`
	display: grid;
	grid-auto-columns: 200px;
	grid-column-gap: 32px;
	grid-row-gap: 32px;

	width: min-content;
	justify-self: center;

	@media ${mobile} {
		grid-template-areas: "a" "b" "c";
	}

	@media ${tablet} {
		grid-template-areas: "a b" "c c";
	}

	@media ${desktop} {
		grid-template-areas: "a b c";
	}

	${Instructor}:nth-of-type(1) {
		grid-area: a;
	}

	${Instructor}:nth-of-type(2) {
		grid-area: b;
	}

	${Instructor}:nth-of-type(3) {
		grid-area: c;
	}
`

const Content = styled.div`
	padding: 2em 0;
	display: grid;
`

const Instructors = styled.section``

const Section = () => {
	const data = useStaticQuery(graphql`
		{
			allStrapiInstructors {
				nodes {
					crew {
						fullName
						avatar {
							publicURL
						}
					}
					course {
						name
					}
				}
			}
		}
	`)

	const instructors = data.allStrapiInstructors.nodes

	return (
		<Instructors>
			<Content>
				<Info
					title={"Our Instructors"}
					detail={
						"We are fully of experience with full responsiblity on helping students to reach their end goals."
					}
					center
				/>

				<List>
					{instructors.map((item, index) => {
						return (
							<Instructor key={index} name={item.crew.fullName} imageUrl={item.crew.avatar.publicURL} />
						)
					})}
				</List>
			</Content>
		</Instructors>
	)
}

export default Section
