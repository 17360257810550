import React from "react"
import tw from "twin.macro"
import styled from "@emotion/styled"

const Overlay = styled.div`
	background-color: ${props => props.overlayColor};
	opacity: 0.5;
	position: absolute;
	width: 100%;
	height: 100%;
	z-index: -1;
`

const Background = styled.img`
	object-fit: cover;
	position: absolute;
	width: 100%;
	height: 100%;
	z-index: -2;
`

const Title = styled.div`
	${tw`text-2xl md:text-2xl lg:text-3xl pb-6`}
	font-weight: bold;

	width: 100%;
	color: white;
	align-self: flex-end;
	text-align: center;
`

const Course = styled.div`
	min-height: 50vh;
	overflow: hidden;
	position: relative;
	object-fit: cover;
	display: flex;
`

const CourseItem = ({ title, imageUrl, imageAlt, overlayColor = "black", ...props }) => {
	return (
		<Course {...props}>
			<Background src={imageUrl} alt={title} />
			<Overlay overlayColor={overlayColor} />
			<Title>{title}</Title>
		</Course>
	)
}

export default styled(CourseItem)``
