import React from "react"
import tw from "twin.macro"
import styled from "@emotion/styled"

import * as Color from "../../utils/color"

const Name = styled.div`
	${tw`text-base md:text-lg`}
	font-weight: bold;
	text-transform: uppercase;
`

const Position = styled.div`
	${tw`md:text-xs`}
	text-transform: uppercase;
	font-weight: bold;
	font-size: 0.7em;
	color: ${Color.secondary};
`

const Image = styled.img`
	${tw`mb-4`}
	width: 200px;
	height: 200px;
	border-radius: 50%;
	object-fit: cover;
	justify-self: center;
`

const Instructor = styled.div`
	display: grid;
	text-align: center;
	color: ${Color.primary};
`

const InstructorItem = ({ name, imageUrl, ...props }) => {
	return (
		<Instructor {...props}>
			<Image src={imageUrl} alt={name} />
			<Position>Instructor</Position>
			<Name>{name}</Name>
		</Instructor>
	)
}

export default styled(InstructorItem)``
