import React from "react"
import Intro from "../sections/academy/intro"
import Instructors from "../sections/academy/instructors"

const Wrapper = () => {
	return (
		<>
			<Intro />
			<Instructors />
		</>
	)
}

export default Wrapper
