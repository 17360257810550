import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import tw from "twin.macro"
import styled from "@emotion/styled"

import CourseItem from "../../../components/CourseItem"

import * as Color from "../../../utils/color"
import { mobile, tablet, desktop } from "../../../utils/breakpoint"

const List = styled.div`
	display: grid;
	grid-auto-columns: 1fr;
	grid-auto-rows: 1fr;

	@media ${mobile} {
		grid-template-areas: "basic" "advanced" "plc";
	}

	@media ${tablet} {
		grid-template-areas: "basic advanced" "plc plc";
	}

	@media ${desktop} {
		grid-template-areas: "basic advanced plc";
	}

	${CourseItem}:nth-of-type(1) {
		grid-area: basic;
	}

	${CourseItem}:nth-of-type(2) {
		grid-area: advanced;
	}

	${CourseItem}:nth-of-type(3) {
		grid-area: plc;
	}
`

const Detail = styled.p`
	${tw`text-base md:text-lg`}
	width: 60%;
`

const Header = styled.h2`
	${tw`text-5xl md:text-6xl`}
	font-weight: bold;
	color: ${Color.primary};
`

const Info = styled.div`
	${tw`mb-8`}
	color: ${Color.primary};

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
`

const Content = styled.div`
	padding: 2em 0;
`

const Intro = styled.section``

const Section = () => {
	const data = useStaticQuery(graphql`
		{
			allStrapiCourses {
				edges {
					node {
						name
						path
						thumbnail {
							publicURL
						}
						color
					}
				}
			}

			strapiAcademyPage {
				introduction {
					detail
					head
				}
			}
		}
	`)

	const introduction = data.strapiAcademyPage.introduction
	const courses = data.allStrapiCourses.edges

	return (
		<Intro>
			<Content>
				<Info>
					<Header>{introduction.head}</Header>
					<Detail>{introduction.detail}</Detail>
				</Info>

				<List>
					{courses.map(({ node }, index) => (
						<CourseItem
							key={index}
							title={node.name}
							imageUrl={node.thumbnail.publicURL}
							overlayColor={node.color}
						/>
					))}
				</List>
			</Content>
		</Intro>
	)
}

export default Section
